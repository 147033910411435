<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false"></nav-form>
    <section>
      <article style="height: 422px; width: 707px">
        <r-tabs style="top: 0px; left: 0px; height: 420px" :buttons="['General', 'Certificado', 'Notas']">
          <div class="tab">
            <field name="familia_id" searchable widget="m2o" dbAdapter="familia" label="Familia" inline-label
              placeholder="Familia" width="270px" style="top: 10px; right: 353px" required :fields="[
                'unidad_medida_compra',
                'compra_metal_incluido',
                'unidad_medida_venta',
                'venta_metal_incluido',
                'recargo_equivalencia',
                'nombre',
                'control_inventario',
                { name: 'tipo_comision_id', fields: ['descripcion'] },
                { name: 'seccion_id', fields: ['nombre'] },
                { name: 'atributos', fields: ['nombre'] },
              ]" @select="onSelectFamilia" ref="familia_id" />
            <field name="modelo_id" widget="m2o" dbAdapter="modelo" label="Modelo" inline-label searchable required
              placeholder="Modelo del Articulo" help="Modelo del Articulo" width="270px" style="top: 35px; right: 353px"
              :fields="[
                'nombre',
                'unidad_medida_compra',
                'compra_metal_incluido',
                'unidad_medida_venta',
                'venta_metal_incluido',
                'recargo_equivalencia',
                'codigo_proveedor',
                'notas',
                'control_inventario',
                { name: 'thumbnail', fields: ['imagen'] },
                { name: 'thumbnail1', fields: ['imagen'] },
                { name: 'thumbnail2', fields: ['imagen'] },
                { name: 'thumbnail3', fields: ['imagen'] },
                { name: 'thumbnail4', fields: ['imagen'] },
                { name: 'tipo_comision_id', fields: ['descripcion'] },
                { name: 'marca_id', fields: ['nombre'] },
                { name: 'familia_id', fields: ['nombre'] },
                { name: 'seccion_id', fields: ['nombre'] },
                { name: 'proveedor_id', fields: ['nombre'] },
                {
                  name: 'taqueria_ids',
                  fields: ['nombre', { name: 'thumbnail', fields: ['imagen'] }],
                },
                {
                  name: 'colecciones',
                  fields: ['nombre', { name: 'imagen', fields: ['imagen'] }],
                },
                {
                  name: 'atributos',
                  fields: [
                    { name: 'atributo_id', fields: ['nombre'] },
                    'nombre']
                },
                {
                  name: 'certificados',
                  fields: [
                    { name: 'naturaleza', fields: ['descripcion'] },
                    { name: 'talla', fields: ['descripcion'] },
                    { name: 'color', fields: ['descripcion'] },
                    { name: 'pureza', fields: ['descripcion'] },
                    'npiedras',
                    'pesogema',
                  ],
                },
              ]" @select="onSelectModelo" ref="modelo_id" />
            <field ref="codigo" name="codigo" widget="char" searchable label="Código" inline-label
              help="Código del artículo" placeholder="Cód. artículo" width="90px" style="top: 60px; right: 534px"
              :readonly="mode == 'edit'" inputStyle="font-weight:bold;text-align:center;" @change="onChangeCodigo" />
            <field name="ean13" widget="char" label="Cód.Barras" help="Cód.Barras" inline-label placeholder="Cód. Barras"
              width="110px" style="top: 60px; right: 353px" />
            <field name="nombre" widget="char" searchable label="Descripción" inline-label help="Descripción del artículo"
              required placeholder="Descripción del modelo" width="270px" style="top: 85px; right: 353px" />

            <field name="seccion_id" searchable widget="m2o" label="Sección" inline-label required
              help="Sección a la que pertenece la Familia" width="173px" style="top: 110px; right: 450px" />
            <field name="talla" widget="char" searchable label="Talla" inline-label help="Talla" width="60px"
              style="top: 110px; right: 353px" />
            <field name="marca_id" widget="m2o" searchable label="Marca" inline-label placeholder="Marca" width="270px"
              style="top: 135px; right: 353px" />
            <field name="taqueria_ids" widget="m2m" dbAdapter="articulo" searchable label="Taquería" inline-label
              :filter="['seccion_id', '=', 'PCK']" placeholder="Taquería" width="270px" style="top: 160px; right: 353px"
              :height="77" imgProp="thumbnail" />

            <field name="thumbnail" widget="m2o_img" dbAdapter="imagen_articulo" searchable
              style="width: 90px; height: 90px; top: 250px; left: 10px" />
            <field name="thumbnail1" widget="m2o_img" searchable
              style="width: 30px; height: 30px; top: 250px; left: 105px" inputStyle="left:calc(100% + 5px);top:0px;"
              closeButtonStyle="left:calc(100% + 75px);top:0px;" />
            <field name="thumbnail2" widget="m2o_img" searchable
              style="width: 30px; height: 30px; top: 280px; left: 105px" inputStyle="left:calc(100% + 5px);top:0px;"
              closeButtonStyle="left:calc(100% + 75px);top:0px;" />
            <field name="thumbnail3" widget="m2o_img" searchable
              style="width: 30px; height: 30px; top: 310px; left: 105px" inputStyle="left:calc(100% + 5px);top:0px;"
              closeButtonStyle="left:calc(100% + 75px);top:0px;" />
            <field name="thumbnail4" widget="m2o_img" searchable
              style="width: 30px; height: 30px; top: 340px; left: 105px" inputStyle="left:calc(100% + 5px);top:0px;"
              closeButtonStyle="left:calc(100% + 75px);top:0px;" />

            <field name="recargo_equivalencia" widget="toggle" inline-label :labels="{ checked: 'Sí', unchecked: 'No' }"
              label="R. Equiv." :width="50" style="top: 250px; right: 353px" />

            <field name="codigo_proveedor" widget="char" label="Ref. proveedor" placeholder="Ref. Proveedor" width="100px"
              style="top: 290px; right: 353px" />

            <field name="tipo_comision_id" widget="m2o" labelProp="codigo" searchable label="Comisión"
              placeholder="Comisión" width="100px" style="top: 330px; right: 353px"
              :templateResult="(item) => item.codigo" :templateSelection="(item) => item.codigo" />

            <fieldset style="
                            position: absolute;
                            right: 10px;
                            top: 5px;
                            width: 330px;
                            height: 55px;
                          ">
              <legend>Unidad de Medida en Compras:</legend>
              <field name="unidad_medida_compra" widget="radio" default="unidad"
                :options="{ Unidad: 'unidad', Peso: 'peso' }" inline-label style="top: 3px; left: 15px" />
              <field name="compra_metal_incluido" widget="checkbox" label="Compra con Metal Incluido" inline-label
                style="top: 3px; left: 147px" />
            </fieldset>
            <fieldset style="
                            position: absolute;
                            right: 10px;
                            top: 65px;
                            width: 330px;
                            height: 55px;
                          ">
              <legend>Unidad de Medida en Ventas:</legend>
              <field name="unidad_medida_venta" widget="radio" default="unidad"
                :options="{ Unidad: 'unidad', Peso: 'peso' }" inline-label style="top: 3px; left: 15px" />
              <field name="venta_metal_incluido" widget="checkbox" label="Venta con Metal Incluido" inline-label
                style="top: 3px; left: 147px" />
            </fieldset>
            <fieldset style="
                            position: absolute;
                            right: 10px;
                            top: 125px;
                            width: 330px;
                            height: 62px;
                          ">
              <legend>Control Inventario</legend>
              <field name="control_inventario" widget="radio" default="input"
                :options="{ Unidad: 'unidad', Peso: 'peso', Ambos: 'ambos' }" style="top: 3px; left: 85px" />
            </fieldset>
            <fieldset style="
                            position: absolute;
                            right: 10px;
                            bottom: 9px;
                            width: 330px;
                            height: 175px;
                          ">
              <legend>Atributos:</legend>
              <field name="atributos" widget="handsontable" searchable :height="150" :minRows="5"
                style="top: 3px; left: 10px; width: 310px" :htSettings="htSettingsAtributos" :columns="[
                  {
                    name: 'atributo_id',
                    header: 'Atributo',
                    type: 'm2o',
                    label: 'nombre',
                  },
                  {
                    name: 'id',
                    header: 'Valor',
                    type: 'm2o',
                    label: 'nombre',
                    model: 'valor_atributo',
                    filter: (value, row, prop) =>
                      value && value['atributo_id']
                        ? ['atributo_id', '=', value['atributo_id']]
                        : [],
                  },
                ]" />
            </fieldset>
          </div>
          <div class="tab">
            <field name="certificados" widget="handsontable" :height="285" :width="680" :minRows="12"
              style="top: 10px; left: 10px" customprop="certificados_customprop" :columns="[
                {
                  name: 'naturaleza',
                  header: 'Naturaleza',
                  type: 'm2o',
                  label: 'descripcion',
                  primary: 'codigo',
                  showCode: true,
                },
                {
                  name: 'talla',
                  header: 'Talla',
                  type: 'm2o',
                  label: 'descripcion',
                  primary: 'codigo',
                },
                {
                  name: 'npiedras',
                  header: 'N. Piedras',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0' },
                },
                {
                  name: 'pesogema',
                  header: 'Peso Gema',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'color',
                  header: 'Color',
                  type: 'm2o',
                  label: 'descripcion',
                  primary: 'codigo',
                },
                {
                  name: 'pureza',
                  header: 'Pureza',
                  type: 'm2o',
                  label: 'descripcion',
                  primary: 'codigo',
                },
              ]" />
            <field name="peso" widget="float" type="number" searchable label="Peso" inline-label help="Peso" width="80px"
              style="bottom: 10px; left: 10px" suffix=" gr" />
          </div>
          <!--<div class="tab">
            <fieldset style="position: relative; left: 10px; top: 15px; width: 410px; height: 56px">
              <legend>Stock mínimo y óptimo:</legend>
              <field
                name="stock_minimo"
                widget="int"
                label="Mínimo"
                inline-label
                width="70px"
                style="top:23px; left:10px;"
              />
              <field
                name="stock_optimo"
                widget="int"
                label="Óptimo"
                inline-label
                width="70px"
                style="top:23px; left:140px;"
              />
              <field
                name="stock_por"
                widget="select"
                label="Por"
                inline-label
                :options="{'Unidades':'unidades', 'Peso':'Peso', 'Ambos':'ambos'}"
                width="90px"
                style="top:23px; left:280px;"
              />
            </fieldset>
          </div>-->
          <div class="tab">
            <field name="notas" widget="text" placeholder="Escriba aquí las notas del artículo..." width="350px"
              height="270px" style="top: 10px; left: 10px" />
            <field name="colecciones" label="Colecciones" widget="m2m" dbAdapter="coleccion" searchable width="315px"
              style="top: 10px; right: 10px" :height="130" imgProp="imagen" />
          </div>
        </r-tabs>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Artículos",
      dbAdapter: "articulo",
      primary: "codigo",
      defaultData: {
        activo: 1,
      },
      sequence: {
        name: "articulo",
        params: [
          "familia_id.codigo",
          "seccion_id.codigo",
          "modelo_id.codigo",
          "proveedor.codigo",
          "talla",
        ],
        cancel: !!self.initialFormData.codigo,
      },
      codigo_duplicado: false,
      htSettingsAtributos: {
        cells(row, col, prop) {
          let readOnly = prop == 'id' && !this.instance.getSourceData()[row].atributo_id;
          return readOnly ? { readOnly } : {}
        },
        beforeChange(changes, source) {
          if (!changes) return;
          let h = this;
          changes.forEach(function (change, index) {
            if (!change) return;
            const row = change[0];
            const prop = change[1];
            const newVal = change[3];
            if (prop == 'id' && h.getSourceData().find(x => x.id == newVal)) {
              self.app.toast('Ya está añadido ese valor de atributo', 'error');
              changes[index][3] = null;
            }
          });
        }
      }
    };
  },
  watch: {
    mode: function (newVal, oldVal) {
      var self = this;
      if (newVal == "new") {
        window.DB.action("articulo", "getNextBarcode")
          .then(function (res) {
            self.$set(self.formData, "ean13", res.ean13);
          })
          .catch(function (res) {
            window.console.log(res);
          });
      }
    },
  },
  computed: {
    ptarifa() {
      var self = this;
      return function (n) {
        let precio = parseFloat(self.formData["tarifa" + n] || 0);
        if (!precio) return "";
        let pcoste = self.formData.precio_coste || 0;
        var calc = 100 * (precio / pcoste - 1);
        if (!isFinite(calc)) calc = 0;
        calc = calc.toFixed(2) + "%";
        return calc;
      };
    },
  },
  methods: {
    onChangePTarifa(e) {
      var self = this;
      let pcoste = self.formData.precio_coste || 0;
      let p = (parseFloat(e.target.value) || 0) / 100;
      let name = e.target.name.substring(1);
      self.$set(self.formData, name, pcoste * (1 + p));
    },
    onSelectModelo() {
      var self = this;
      if (self.mode != "new" && self.mode != "edit") return;
      if (!self.formData.modelo_id) return;
      for (let c of self.$refs.modelo_id.field.fields) {
        if (typeof c == "object") c = c.name;
        var v = self.formData.modelo_id[c];
        self.$set(self.formData, c, v);
      }
    },
    onSelectFamilia() {
      var self = this;
      if (self.mode != "new" && self.mode != "edit") return;
      if (!self.formData.familia_id) return;
      for (let c of self.$refs.familia_id.field.fields) {
        if (typeof c == "object") c = c.name;
        var v = self.formData.familia_id[c];
        if (c == "atributos") {
          v = (self.formData.familia_id.atributos || []).map((el) => ({
            atributo_id: el,
          }));
        }
        self.$set(self.formData, c, v);
      }
    },
    onChangeCodigo: function (e, source) {
      var self = this;
      if (
        !self.formData.codigo ||
        self.mode == "search" ||
        self.mode == "empty" ||
        self.mode == "view"
      ) {
        self.sequence.cancel = false;
        return;
      }
      self.sequence.cancel = true;
      let conditions = ["codigo", "=", self.formData.codigo];
      if (self.itemId) conditions.push(["codigo", "!=", self.itemId]);
      window.DB.getList(self.dbAdapter, {
        filter: conditions,
        fields: [self.primary],
      }).then(function (res) {
        if (parseInt(res.count)) {
          self.$set(
            self.errors,
            "codigo",
            "Ya existe un artículo con este código"
          );
        } else {
          self.$delete(self.errors, "codigo");
        }
      });
    },
  },
  mounted() {
    var self = this;
    window.DB.action("articulo", "getNextBarcode")
      .then(function (res) {
        self.$set(self.formData, "ean13", res.ean13);
        setTimeout(function () {
          window.$(self.$refs.familia_id.field.$el).find(".select2").click();
        }, 500);
      })
      .catch(function (res) {
        window.console.log(res);
      });

    if (self.initialFormData.modelo_id) {
      let fields = [self.$refs.modelo_id.field.labelProp];
      fields = [...fields, ...self.$refs.modelo_id.field.fields];
      window.DB.get("modelo", {
        itemId: self.initialFormData.modelo_id,
        fields,
      }).then(function (res) {
        self.initialFormData.modelo_id = res;
        self.$set(self.formData, "modelo_id", res);
        self.onSelectModelo();
      });
    }
  },
};
</script>